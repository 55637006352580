@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&family=M+PLUS+1+Code:wght@400;500;700&family=Montserrat:wght@600&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto+Mono:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:wght@400;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&display=swap');


//@font-face {
//  font-family: 'Material Icons';
//  font-style: normal;
//  font-weight: 400;
//  src: url('../fonts/MaterialIcons-Regular.ttf') format('truetype');
//  //src: url('../fonts/MaterialIcons-Regular.eot'); /* For IE6-8 */
//  //src: local('Material Icons'),
//  //local('MaterialIcons-Regular'),
//  //url('../fonts/MaterialIcons-Regular.woff2') format('woff2'),
//  //url('../fonts/MaterialIcons-Regular.woff') format('woff'),
//  //url('../fonts/MaterialIcons-Regular.ttf') format('truetype');
//}
//.material-icons {
//  font-family: 'Material Icons';
//  font-weight: normal;
//  font-style: normal;
//  font-size: 24px;  /* Preferred icon size */
//  display: inline-block;
//  line-height: 1;
//  text-transform: none;
//  letter-spacing: normal;
//  word-wrap: normal;
//  white-space: nowrap;
//  direction: ltr;
//
//  /* Support for all WebKit browsers. */
//  -webkit-font-smoothing: antialiased;
//  /* Support for Safari and Chrome. */
//  text-rendering: optimizeLegibility;
//
//  /* Support for Firefox. */
//  -moz-osx-font-smoothing: grayscale;
//
//  /* Support for IE. */
//  font-feature-settings: 'liga';
//}
///* Rules for sizing the icon. */
//.material-icons.md-18 { font-size: 18px; }
//.material-icons.md-24 { font-size: 24px; }
//.material-icons.md-36 { font-size: 36px; }
//.material-icons.md-48 { font-size: 48px; }
//
///* Rules for using icons as black on a light background. */
//.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
//.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }
//
///* Rules for using icons as white on a dark background. */
//.material-icons.md-light { color: rgba(255, 255, 255, 1); }
//.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }