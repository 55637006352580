@import 'variables.scss';

@include margin-variant(3, $unit-3);
@include margin-variant(4, $unit-4);
@include margin-variant(6, $unit-6);
@include padding-variant(3, $unit-3);
@include padding-variant(4, $unit-4);
@include padding-variant(6, $unit-6);


.ws-pl { white-space: pre-line; }

.text-underline { text-decoration: underline; }

.table {
  th { text-transform: uppercase; }
  //td { border: $border-width solid $border-color; }
  //td, th { padding: $unit-2 $unit-2; }
}

.tooltip::after { font-family: $base-font-family; font-weight: 400; font-style: normal; }

.accordion-body { box-sizing: border-box; }
.accordion-header:hover {
  background-color: $secondary-color;
}

.flex-1 { flex: 1 0; }
.flex-2 { flex: 2 0; }
.text-nowrap { white-space: nowrap; }
.text-preline { white-space: pre-line; }
.text-pre { white-space: pre; }
.text-hover-primary:hover { color: $primary-color; }

.btn-material-icon span, .valign-middle {
  vertical-align: middle;
}

.valign-text-top {
  vertical-align: text-top;
}

.divider.divider-black {
  border-color: black;
}

// the same height as inputs and buttons
.loading.loading-md {
  min-height: $control-size;

  &:after {
    height: 1.4rem;
    margin-left: -0.7rem;
    margin-top: -0.7rem;
    width: 1.4rem;
  }
}

.justify-center { justify-content: center; }
.align-end { align-items: end; }

.booked-at-logo {
  height: 24px;
  width: 24px;
  //max-width: 24px;
}

.font-courier-new { font-family: 'Courier New', monospace; }
//.font-nanum { font-family: "Nanum Gothic Coding", monospace; }

.table-sm {
  td, th { padding: $unit-2 $unit-1; }
  th { border-bottom-width: $border-width; }
}

.table-xs {
  td, th { padding: $unit-1 $unit-h; }
  th { border-bottom-width: $border-width; }
}

// ----------------- Colors -------------------

.text-hopbush { color: $hopbush; };
.text-bouquet { color: $bouquet; };
.text-venus { color: $venus; };
.text-patina { color: $patina; };
.text-nebula { color: $nebula; };
.text-dawn-pink { color: $dawn-pink; };
.text-wafer { color: $wafer; };
.text-iron { color: $iron; };
.text-regent-grey { color: $regent-grey; };
.text-pale-sky { color: $pale-sky; };
.text-midnight-blue { color: $midnight-blue; };

.bg-hopbush { background-color: $hopbush; };
.bg-bouquet { background-color: $bouquet; };
.bg-venus { background-color: $venus; };
.bg-patina { background-color: $patina; };
.bg-nebula { background-color: $nebula; };
.bg-dawn-pink { background-color: $dawn-pink; };
.bg-wafer { background-color: $wafer; };
.bg-iron { background-color: $iron; };
.bg-regent-grey { background-color: $regent-grey; };
.bg-pale-sky { background-color: $pale-sky; };
.bg-midnight-blue { background-color: $midnight-blue; };

// ----------------- Select.js ----------------

.custom-select {
  position: relative;
  .menu {
    position: absolute;
    min-width: 100%;
    max-height: 12rem;
    overflow-y: auto;
  }
  .menu-item {
    cursor: default;
  }

  .has-icon-right {
    input[readonly] {
      background-color: $bg-color-light;
      cursor: default;
    }

    .form-input { padding-right: $control-icon-size + $control-padding-x * 2; }
    .form-icon {
      margin: 0 $control-padding-x;
      z-index: auto;
    }

    .form-input.input-sm { padding-right: $control-icon-size + $control-padding-x-sm * 2; }
    .form-input.input-sm + .form-icon { margin: 0 $control-padding-x-sm; }

    .form-input.input-lg { padding-right: $control-icon-size + $control-padding-x-lg * 2; }
    .form-input.input-lg + .form-icon { margin: 0 $control-padding-x-lg; }
  }
}

.custom-multiselect {
  .form-autocomplete-input {
    min-height: $unit-9;
    padding: $unit-o;
    padding-right: $control-icon-size + $control-padding-y * 2;

    .chip {
      border-radius: 0;
      padding-left: $unit-1;
      height: $unit-7;
      //font-size: 100%;

      .btn-clear {
        border-radius: 0;
        transform: scale(.75);
        margin-left: -2px;
        margin-right: 0;
      }
    }

    .form-input {
      min-width: 0;  // https://stackoverflow.com/a/42421490
      height: $unit-7;
      padding-left: $control-padding-x / 4;
      width: inherit;
      flex: 1 0 10px;
    }
    .form-input[readonly] {
      background-color: $bg-color-light;
      cursor: default;
    }

    .form-icon {
      height: $control-icon-size;
      margin: 0 $control-padding-y;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: $control-icon-size;
      z-index: auto;

      right: $border-width;
    }
  }

  .menu {
    max-height: 12rem;
    overflow-y: auto;
  }
  .menu-item {
    cursor: default;
  }
}

// ------------ input[type="file"] ------------

input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
input[type="file"] + label * {
  pointer-events: none;
}

.file-tile {
  .tile-icon {
    align-content: space-around;
    align-items: center;
    background: $primary-color;
    border-radius: .1rem;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 1.2rem;
    height: 2rem;
    width: 2rem;

    .icon { margin: auto; }

    &.loading::after {
      border-left-color: white;
      border-bottom-color: white;
    }
  }

  .tile-content {
    display: flex;
    flex-direction: column;
    height: 2rem;
  }
}

// ------------ MonthCalendar ------------

.DayPicker-Weekdays, .DayPicker-Body {
  font-family: 'Roboto', sans-serif;
  //font-size: 1em !important;
  //line-height: 1.15 !important;
}
.DayPicker {
  font-size: inherit !important;

  // стили ниже расположены внутри .DayPicker для того чтобы они применялись без !important
  .DayPicker.DayPicker .DayPicker-Day--today { color: $primary-color; }
  .DayPicker-TodayButton { color: $primary-color; }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) { background-color: $primary-color; }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover { background-color: $primary-color-light; }
}

.DayPickerInput { width: 100%; }
.DayPickerInput-OverlayWrapper { z-index: 3; }

.InputFromTo {
  display: flex;
  align-items: center;
  position: relative;

  .DayPickerInput-OverlayWrapper {
    position: absolute;
    top: $control-size;
    left: 0;
    width: 550px;
    z-index: 3;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .DayPicker-Day {
    border-radius: 0 !important;
  }
  .DayPicker-Day--start {
    background-color: #4A90E2 !important;
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .DayPicker-Day--end {
    background-color: #4A90E2 !important;
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}

// ------------ react-modal ------------
// при указании собственного класса дефолтные стили не передаются
.Modal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.DimmedModal__Overlay {
  @extend .Modal__Overlay;
  background-color: rgba(100,100,100,0.75) !important;
}

.Modal__Content {
  position: absolute;
  top: 40px;
  right: 40px;
  left: 40px;
  bottom: 40px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}


.loading-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: block;
  background: rgba($bg-color, .75);
  cursor: default;
  z-index: 10;

  .loading {
    position: absolute;
    top: calc(25vh - 0.8rem);
    left: calc(50% - 0.8rem);
  }
}