@import '~spectre.css/src/variables';
@import 'variables.scss';

#sign-in-form {
  max-width: 20rem;
  margin: 3em auto;
  img { width: 100%; }
}

#client-form {
  textarea { resize: vertical; height: 275px }
}

#machine-form {
  #spares-container .form-radio { margin: 0; }

  // applies to #service.end, #spares.end, #inspection.end
  .InputFromTo .DayPickerInput:nth-child(2) .DayPickerInput-OverlayWrapper {
    left: -198px;
  }
}

#report-form {
  textarea { resize: vertical; }
  #problem, #solution { min-height: 12em; }

  #status option { color: $body-font-color; }

  #replacements-table .replacement-item {
    display: flex;
    align-items: center;

    margin-top: $unit-2;
    padding-bottom: $unit-2;
    > * { margin: 0 $unit-1;  }
    > *:first-child { margin-left: 0; }
    .btn-action { margin: 0 auto 0 $unit-1; flex: 0 0 1.8rem; } // last-child
    > input, .custom-select { flex: 1; }

    .menu-item a { white-space: nowrap; }
  }

  #expenses-table {
    position: relative;
    input[type="file"] { position: absolute; }
  }
  #expenses-files-modal .modal-container { max-width: 440px; }

  .btn-add-item {
    width: 40%;
    margin: 0.1rem auto;
    background-color: $gray-color-light;
    height: 0.4rem;
    cursor: pointer;
    &:hover {
      background-color: $gray-color;
    }
  }

  #work-hours-table .work-hours-table-row:hover,
  #travel-hours-table {
    .travel-hours-table-row:hover {
      background-color: $gray-color-light;

      input, select {
        background-color: $secondary-color-light;
      }
    }

    ul {
      margin-top: 0;
      list-style: disc;

      li {
        margin-top: $unit-1;
      }
    }

    .material-icons {
      font-size: 21px;
      vertical-align: top;
    }
  }
}