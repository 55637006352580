#root {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1em;
}

#loading-container {
  height: 100vh;
  align-items: center;
  justify-content: center;
}
#loading-text {
  margin-top: 0.2rem;
  margin-left: 1.5rem;
}

#navbar {
  .btn-group {
    flex-wrap: nowrap;
  }
  .btn-link.active {
    background: $primary-color;
    color: white;
    box-shadow: none;
  }

  #navbar-btn__payments svg {
    height: 24px;
    width: auto;
  }
  #navbar-btn__bills svg {
    height: 24px;
    width: auto;
    min-width: 24px;
  }
  .navbar-btn-svg-icon {
    fill: $primary-color;
  }
  .btn-link.active .navbar-btn-svg-icon {
    fill: white;
  }
}

//@media (max-width: $size-md) {
//  #navbar { overflow-x: scroll; }
//}

//.scroll-up-button {
//  transition: background-color 200ms ease-out,opacity 200ms ease-out,width 200ms ease-out;
//  background-color: rgb(244, 244, 244);;
//  cursor: pointer;
//  height: 100%;
//  left: 0;
//  position: fixed;
//  top: 0;
//  bottom: 0;
//  z-index: 4999;
//  text-align: center;
//  padding-top: 10px;
//  width: 40px;
//  backface-visibility: hidden;
//}