#calendar-page {
  width: 1400px;
  transform: translateX(-100px);
  position: relative;

  /* ----------------------------------------------------- */

  #year-calendar {
    //border-radius: 5px;
    border: 1px solid #5A5A5A;
    background-color: white;
    overflow: hidden;
    user-select: none;
  }

  .y-calendar-controls .control {
    font-size: inherit;
    &:hover {
      color: $primary-color;
    }
  }

  /* table.y-calendar td. */.nonWorkDay, .DayPicker-Day--nonWorkDay .DayPicker-DayNumber {
    font-style: italic;
    font-weight: bold;
  }
  /* table.y-calendar td. */.today, .DayPicker-Day--today .DayPicker-DayNumber {
    font-weight: bold;
    color: red;
    text-decoration: underline;
  }

  .illness, .DayPicker-Day--illness { background-color: rgba(164, 128, 255, 0.75); }
  .vacationUnpaid, .DayPicker-Day--vacationUnpaid { background-color: rgba(246, 60, 60, 0.32); }
  .vacationPaid, .DayPicker-Day--vacationPaid { background-color: rgba(246, 60, 60, 0.75); }
  .timeOffUnpaid, .DayPicker-Day--timeOffUnpaid { background-color: rgba(246, 60, 60, 0.32); }
  .timeOffPaid, .DayPicker-Day--timeOffPaid { background-color: rgba(246, 60, 60, 0.75); }
  .workFromHome, .DayPicker-Day--workFromHome { background-color: rgba(159, 220, 249, 0.32); }
  .workFromHomeNWD, .DayPicker-Day--workFromHomeNWD { background-color: rgba(159, 220, 249, 1); }
  .workFromOffice, .DayPicker-Day--workFromOffice { background-color: rgba(145, 212, 110, 0.32); }
  .workFromOfficeNWD, .DayPicker-Day--workFromOfficeNWD { background-color: rgba(145, 212, 110, 1); }
  .fieldWorkInHomeCity, .DayPicker-Day--fieldWorkInHomeCity { background-color: rgba(255, 232, 91, 0.32); }
  .fieldWorkInHomeCityNWD, .DayPicker-Day--fieldWorkInHomeCityNWD { background-color: rgba(255, 232, 91, 1); }
  .fieldWorkInOtherCity, .DayPicker-Day--fieldWorkInOtherCity { background-color: rgba(246, 151, 60, 0.32); }
  .fieldWorkInOtherCityNWD, .DayPicker-Day--fieldWorkInOtherCityNWD { background-color: rgba(246, 151, 60, 1); }

  table.y-calendar td.range,
  table.y-calendar td.selected {
    background-color: black !important;
    color: white !important;
  }

  /* ----------------------------------------------------- */

  ul.color-legend {
    margin: 0;
    margin-bottom: $unit-2;
    padding: 0;
    list-style: none;
  }
  ul.color-legend li {
    list-style: none;
  }
  ul.color-legend li span {
    display: inline-block;
    vertical-align: middle;
  }
  ul.color-legend li span:first-child {
    height: 1.2rem;
    width: 1.2rem;
    border: 1px solid #999;
    margin-right: $unit-2;
  }

  /* ----------------------------------------------------- */

  table.y-calendar td.badge::after, .color-legend span.badge::after {
    // copied from file _badges.scss, .avatar.badge::after selector
    position: absolute;
    top: 14.64%;
    right: 14.64%;
    transform: translate(50%, -50%);
    z-index: $zindex-1;

    // default is 6px, and it looks too big
    height: 5px;
    min-width: 5px;
    padding: 0;
    width: 5px;
  }

  #calendar-actions, .day-status-overrides, #legend, #day-content {
    border: 1px solid #B9B9B9;
    //border-radius: 5px;
    padding: 8px 16px;
    margin-top: 10px;
  }

  #calendar-actions .status-group {
    display: flex;
    border: $border-width solid $primary-color;
    border-radius: $border-radius;

    span { width: 1.8rem; }
    button { border: none; }
  }

  .day-status-overrides .tile:not(:last-of-type) {
    border-bottom: 1px solid #eee;
  }
  .day-status-overrides .tile-icon {
    border-radius: .1rem;
    height: 2rem;
    width: 2rem;
  }

  /* ----------------------------------------------------- */

  .DayPicker {
    width: 100%;
    //border: 1px solid #5A5A5A;
  }
  .DayPicker-wrapper {
    padding: 0;
  }
  .DayPicker-Months {
    justify-content: space-between;
  }
  .DayPicker-Month {
    display: block;
    margin: 0;
    flex: 1;

    &:not(:first-of-type) {
      margin-left: $layout-spacing * 2;
    }
  }
  .DayPicker-Caption {
    display: block;

    font-size: 25px;
    line-height: 35px;
    padding: 5px 0.5em;
    margin: 0;

    border: 1px solid #5a5a5a;
    border-bottom: 0;
  }
  .DayPicker-Caption > div {
    font-size: inherit !important;
  }
  .DayPicker-NavButton {
    top: 0.25rem;
    margin-top: 0;
    right: 0.25rem;
    color: black;

    font-size: 1.2rem;
    height: 1.8rem;

    &:hover {
      color: $primary-color;
    }

    &.DayPicker-NavButton--prev {
      margin-right: 1.8rem;
    }
  }
  .DayPicker-Weekdays {
    display: block;
    border-bottom: .05rem solid #5a5a5a;
    margin: 0;
  }
  .DayPicker-WeekdaysRow {
    display: flex;

    border: 1px solid #5a5a5a;
    border-bottom: 0;
    background-color: #5a5a5a;
  }
  .DayPicker-Weekday {
    flex: 1;

    display: block;
    padding: 10px;

    color: white;
    font-family: sans-serif;
    font-size: inherit;
    font-weight: lighter;

    &:nth-child(6), &:nth-child(7) {
      font-weight: bold;
    }
  }
  .DayPicker-Body {
    display: block;
  }
  .DayPicker-Week {
    display: flex;
  }
  .DayPicker-Day {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;

    flex: 1;
    min-width: 4.2rem;
    height: 3.5rem;
    padding: 0.25rem;
    border-radius: 0;
    border-bottom: .05rem solid #5a5a5a;
    border-right: .05rem solid #5a5a5a;

    &:first-child {
      border-left: .05rem solid #5a5a5a;
    }
  }
  .DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background: black;
    color: white
  }

  .DayPicker-Day--outside {
    background-color: #eeeeee;
  }

  .DayPicker-NavButton--prev, .DayPicker-NavButton--next {
    background-image: none;
  }

  .DayPicker-Day--badge {
    @extend .badge;

    &::after {
      position: absolute;
      left: 0.3rem;
      top: 0.3rem;
      transform: none !important;
    }
  }
  .DayPicker-Day--badge.DayPicker-Day--outside::after {
    display: none;
  }
}
