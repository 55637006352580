@import '~spectre.css/src/variables';
@import 'variables.scss';

.custom-table {
  a { color: inherit; }

  .table-row {
    display: flex;
    border-bottom: $border-width solid $border-color;
    border-right: $border-width solid $border-color;
    white-space: pre-wrap;

    > div {
      flex: 1;
      padding: $unit-3 $unit-2;
      border-left: $border-width solid $border-color;
    }

    &:nth-of-type(even) {
      background: $bg-color;
    }
  }

  .table-head {
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    border-right: none;
    div { border: none; }
    label {
      font-weight: normal;
      margin: 0;
    }
  }
}

#clients-table {
  .c-machines table {
    width: 100%;
    .m-serial { text-align: right; }
  }

  .c-contacts {
    position: relative;
    background-clip: padding-box; // https://stackoverflow.com/a/24492118

    .icon.rot90 { transform: rotate(90deg); }
    .icon.rot180 { transform: rotate(180deg); }
    .icon.rot270 { transform: rotate(270deg); }

    .arrow-left, .arrow-right {
      position: absolute;
      top: 0;
      height: 100%;
      &:hover { background-color: lightgreen; }
      i { font-size: 0.8em; }
    }
    .arrow-left { left: 0; }
    .arrow-right { right: 0; }

    .count {
      position: absolute;
      color: grey;
      font-size: 0.8em;
      top: 0.2em;
      right: 1.3em;
    }
  }
}

#machines-table-tabs-container {
  align-items: flex-end;

  .stackoverflow-44348868 { // //https://stackoverflow.com/a/44348868
    flex: 1;
  }

  #machines-table-tabs {
    margin: 0;
    a {
      padding: 0 $unit-1 $border-width-lg $unit-1;
    }
    img {
      height: 2rem;
      max-height: 2rem;
    }
    #aristo-img {
      position: relative;
      top: 0.6rem;
    }
  }
}

#machines-table {
  .text-grey {
    color: grey;
  }

  .table-row > div:nth-child(1),
  .table-row > div:nth-child(2) {
    flex: 1;
  }

  .table-row > div:nth-child(3),
  .table-row > div:nth-child(4) {
    flex: 2;
  }
}


#reports-table-header {
  display: flex;
  div:first-child {
    flex: 1;
  }
}



#reports-table-filters {
  .columns {
    margin-left: -$unit-1;
    margin-right: -$unit-1;
  }
  .column {
    margin-bottom: $unit-2;
    padding: 0 $unit-1;
  }

  .accordion-header { padding-left: 0; }
  input:checked ~ .accordion-body { overflow: visible; } // badges

  .badge[data-badge]::after {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;

    /* стили для badgeй поменьше */
    font-size: 0.6rem;
    height: 0.7rem;
    min-width: 0.7rem;
    padding: 0.1rem 0;
    font-weight: bold;
  }
  .badge[data-badge=""]::after { display: none; }

  #replacements-filter {
    display: flex;

    div {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 0 ($unit-1 + $unit-h);
      border: $border-width solid $border-color-dark;
      border-radius: $border-radius;
    }

    .form-checkbox { margin: 0; }
  }

  #sort-filter {
    display: flex;
    align-items: center;

    select {
      width: auto;
      margin: 0 $unit-2;
      flex: 1;
      text-align: center; // firefox
      text-align-last: center; // chrome
    }
  }

  #timespan-filter .form-autocomplete-input {
    //input[type="date"]::-webkit-calendar-picker-indicator,
    input[type="date"]::-webkit-inner-spin-button {
      display: none;
      -webkit-appearance: none;
    }
    .chip {
      padding: $unit-1;
      background: transparent;
      font-size: 1em;
    }
    .form-input {
      background: $bg-color-dark;
      padding-right: $control-padding-x / 4;
      text-align: center;
    }
  }
}

#reports-table .table-row {
  > div:nth-child(1) {
    // 0.4 + 0.4rem horizontal padding
    // 1rem icon (through font-size)
    // 0.1 + 0.1rem icon padding ($unit-h)
    // 0.2rem left empty
    flex: 0 1 2.2rem;

    > * {
      display: inline-block;
      padding: $unit-h;
      i { font-size: 1rem; }
      i.material-icons, i.material-icons-outlined { font-size: 1.1rem; }
      width: 1.4rem;
      height: 1.4rem;
      &:hover, &:focus { box-shadow: 0 0 0 0.05rem $primary-color; }
    }
  }

  > div:nth-child(2) {
    flex: 1.5;

    hr { margin: $unit-1 0; }

    .client-name {
      font-size: 0.85rem;
      font-weight: bold;
    }
    .client-name, .machine-name {
      line-height: 1.25;
    }
    .machine-serial {
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
    }
    .client-name:hover, .machine-serial:hover {
      color: $primary-color;
      cursor: pointer;
    }
    .visit-info {
      white-space: pre;
      font-weight: 300;
      line-height: 1.3;
    }
  }

  //td { vertical-align: top; }
  //td a { color: inherit; }
  //hr { margin: 0.2rem 0; }

  > div:nth-child(3) {
    flex: 2;

    div[data-bill-id]:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  > div:nth-child(4) { flex: 4; }

  .report__work-type.chip {
    border-radius: 0.5rem;
  }
  .report__work-timetable-row {
    display: flex;
    justify-content: space-between;
  }
  > div:nth-child(5) {
    hr { margin: $unit-h 0; }
  }
}

#bills-list, #payments-list {
  #list-actions {
    select { width: auto; }
    .form-checkbox { margin: 0; }

    .chip {
      display: inline-block;
      border-radius: 0;
      height: 100%;
      padding: 0 $unit-1;
      overflow: visible; // for .custom-select .menu
      max-width: none; // for .custom-select 300px, see below
    }

    .custom-select {
      display: inline-block;
      width: 300px;
    }
  }

  .table-head .custom-select {
    font-size: 90%; // the same as when .custom-select is inside .chip
    font-weight: normal;
    text-transform: none;
  }

  .table-row div:first-child,
  .table-row div:last-child { flex: 2 }

  .table-row div:first-child {
    word-break: break-word;
    span:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .table-row div:last-child .btn-sm {
    line-height: 1rem;
    font-size: 0.65rem;
    padding: 0 0.25rem;
    height: 100%;
    max-height: 1.4rem;
  }
}

//#bills-list {
//  .table-row div:last-child {
//    display: flex;
//    justify-content: space-between;
//  }
//}

.replaced-slots-table {
  font-size: 0.7rem;

  th, td {
    padding: $unit-1 $unit-2;
  }

  td {

    &:not(:first-child) {
      border-left: 1px solid $border-color;
    }
    //&:last-child {
    //  border-right: 1px solid $border-color;
    //}

    vertical-align: top;
  }
}


//.ConfigModal__Content {
//  position: absolute;
//  top: 0;
//  right: 0;
//  left: 0;
//  bottom: 0;
//  //border: 1px solid #ccc;
//  //border-radius: 4px;
//  background: #fff;
//  overflow: auto;
//  -webkit-overflow-scrolling: touch;
//  outline: none;
//  font-size: 12px;
//
//  #config-actions {
//    position: fixed;
//    top: 0;
//    left: 0;
//    right: 0;
//    padding: 6px;
//    background: #fff;
//    border-bottom: 1px solid black;
//
//    b, input { position: relative; top: 3px; }
//    button:not(:first-child) { margin-left: 6px; }
//    b { margin-left: 36px; font-size: 16px; }
//  }
//
//  #config-history {
//    position: fixed;
//    top: 48px;
//    width: 244px;
//    padding-left: 6px;
//
//    .history-row {
//      display: flex;
//      justify-content: space-between;
//
//      margin-top: 6px;
//      &:hover, &.selected {
//        background: #cbcbcb;
//      }
//
//      a { text-decoration: underline; }
//      .status { height: 1em; width: 1em; }
//      .status-0 { background-color: green; }
//      .status-1 { background-color: red; }
//    }
//  }
//
//  #config-rows {
//    margin-top: 48px;
//    overflow: auto;
//  }
//
//  .config-row {
//    display: flex;
//    margin-bottom: 12px;
//
//    input, select { display: block; &.changed { background-color: lightskyblue; } }
//    input { border: 1px dotted black; }
//    select { border: none; }
//  }
//  .config-ph {
//    border: 1px solid black;
//    padding: 3px;
//    margin: 0 6px;
//
//    .ph-header {
//      text-align: center;
//      font-weight: bold;
//    }
//
//    .ph-fields {
//      display: flex;
//    }
//    .ph-field {
//      flex: 1 1 40%;
//      &:not(:first-child) { margin-left: 3px; }
//
//      select, input {
//        width: 77px;
//        box-sizing: border-box;
//      }
//    }
//  }
//  .config-slots {
//    display: flex;
//    margin-top: 3px;
//
//    .config-slot {
//      border: 1px solid black;
//      padding: 3px;
//      &:not(:first-child) { margin-left: 1.5px; }
//      &:not(:last-child) { margin-right: 1.5px; }
//
//      .color-W { background-color: white; }
//      .color-C { background-color: cyan; }
//      .color-K { background-color: black; color: white; }
//      .color-M { background-color: magenta; }
//      .color-Y { background-color: yellow; }
//      .color-m { background-color: #ffc0ff; }
//      .color-c { background-color: #c0ffff; }
//
//      select, input {
//        width: 70px;
//        box-sizing: border-box;
//      }
//    }
//  }
//  .config-ph.status-0, .config-slot.status-0 { background-color: white; } // ILLEGITIMATE
//  .config-ph.status-1, .config-slot.status-1 { background-color: red; } // ILLEGITIMATE
//}