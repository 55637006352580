table.y-calendar {
  border-collapse: collapse;
  font-family: sans-serif;
  width: 100%;
}

table.y-calendar thead {
  background-color: #5A5A5A;
  color: white;
  margin-bottom: 3px;
  border-bottom: 2px solid white
}

table.y-calendar thead th {
  font-weight: lighter;
  padding: 10px 3px;
}

table.y-calendar thead th.sat,
table.y-calendar thead th.sun {
  font-weight: bold;
}

table.y-calendar tbody {
  font-size: 0.6rem;
}

table.y-calendar td {
  text-align: center;
  padding: 8px;
  cursor: pointer;
  border: 1px solid rgba(185, 185, 185, 0.13);
  min-width: 32px;
}

table.y-calendar tr:last-child td {
  border-bottom: none;
}

table.y-calendar td.month-name {
  font-weight: bold;
  text-align: left;
  cursor: default;
  border-left: none;
}

table.y-calendar td.prev-month,
table.y-calendar td.next-month {
  color: transparent;
  cursor: default;
  pointer-events: none;
}

table.y-calendar td.week-separator {
  pointer-events: none;
  padding: 0;
  width: 8px;
  min-width: 0;
}

table.y-calendar td.sat,
table.y-calendar td.sun {
  font-weight: bold;
}

div.y-calendar-controls {
  margin: 5px auto;
  display: table;
  font-size: 25px;
  line-height: 35px;
}

div.y-calendar-controls div {
  display: inline;
}

div.y-calendar-controls .current-year {
  margin: 0 30px;
}

div.y-calendar-controls .control {
  font-weight: bolder;
  color: #323232;
  font-size: 1rem;
  cursor: pointer;
  padding: 0 5px;
}

div.y-calendar-controls .today {
  position: absolute;
  right: 15px;
  line-height: 35px;
  font-size: 0.8rem;
  text-transform: uppercase;
}