.time-nav {
  //background-color: $gray-color-light;
  display: flex;

  select {
    width: auto;
    background: none !important;
    padding: 0 0.8rem !important;
    font-weight: bold;
    text-align: center;

    //@include control-shadow($gray-color);
    &:focus {
      //@include control-shadow($gray-color-dark);
    }
  }

  .time-nav__text,
  .time-nav__inputs {
    border: $border-width solid $border-color-dark;

    flex: 1;
    display: flex;
    align-items: center;

    height: $control-size;
  }
  .time-nav__inputs {
    padding: 0 $unit-2;
  }

  &.time-nav-sm .time-nav__text,
  &.time-nav-sm .time-nav__inputs {
    height: $control-size-sm;
  }

  .time-nav__text-face {
    flex: 1;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  .time-nav__text .btn-action {
    width: calc($control-size + 0.3rem + 0.3rem);
    padding-left: 0.3rem;
    padding-right: 0.3rem;

    &.btn-sm {
      width: calc($control-size-sm + 0.3rem + 0.3rem);
    }
  }

  .time-nav__inputs input {
    outline: none;
    appearance: none;
    border: none;
    background-color: $gray-color-light;
    padding: 0 0.2rem;
    height: 100%;
    flex: 1;

    &:first-child {
      margin-right: $unit-2;
    }

    &:last-child {
      margin-left: $unit-2;
    }
  }

  &.time-nav-narrow {
    select {
      padding: 0 0.5rem !important;
    }

    .time-nav__text, .time-nav__inputs {
      padding: 0;
    }

    .time-nav__inputs input {
      margin: 0;
      padding: 0;
    }

    .time-nav__text .btn-action {
      width: $control-size;
      padding-left: 0;
      padding-right: 0;
    }
  }
}