@import 'fonts.scss';

$primary-color: #AA1E46;
$secondary-color: lighten($primary-color, 47.5%);
$base-font-family: 'Roboto Condensed', sans-serif;
//$html-font-size: 22px;

@import '~spectre.css/src/spectre';
@import '~spectre.css/src/spectre-icons';
@import '~spectre.css/src/spectre-exp';

@import '~colors.css/css/colors.min.css';
.bg-light-aqua { background-color: #e6eeff; }
.bg-light-fuchsia { background-color: #ffdaf2; }

@import '~react-day-picker/lib/style.css';

@import 'common.scss';
@import 'App_Navbar.scss';
@import 'AppLoadingScreen.scss';
@import 'Forms.scss';
@import 'Tables.scss';
@import 'Modals.scss';
@import 'YearCalendar.scss';
@import 'CalendarPage';
@import 'WagesParamsEditPage.scss';
@import 'WagesEngineersTablePage.scss';
@import 'TransportEmailsPage.scss';
@import 'TimeNavigation.scss';
@import 'SpecialDocEditPage.scss';
@import 'PayoutsPage.scss';