#payouts-page {
    table {
        thead tr:first-child th {
            border-bottom-width: 0;
        }

        thead tr:nth-child(1) th:not(:first-child),
        thead tr:nth-child(2) th:nth-child(2n),
        tbody tr td:nth-child(2n) {
            border-left: 0.05rem solid $border-color;
        }

        tbody tr:last-child td {
            border-top: 0.1rem solid $border-color;
        }
    }

    .text-mono {
        font-family: "M PLUS 1 Code", monospace;
        //font-size: 14px;
        //font-weight: 500;
    }

    input {
        display: inline-block;
        height: auto;
        padding: 0 0.4rem;
        width: auto;
    }

    /* HTML: <div class="loader"></div> */
    .loader {
        color: #000;
        width: 4px;
        aspect-ratio: 1;
        border-radius: 50%;
        box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
        //transform: translateX(-38px);
        animation: l21 .5s infinite alternate linear;
    }

    @keyframes l21 {
        50%  {box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px}
        100% {box-shadow: 19px 0 0 0  , 38px 0 0 3px, 57px 0 0 7px}
    }
}