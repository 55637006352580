#wages-engineers-table-page {
  //table th:nth-child(3), td:nth-child(3) {
  //  min-width: 200px;
  //}

  table tr th:not(:first-child) {
    border-left: $border-width solid $border-color;
  }

  table tr.bg-dawn-pink {
    background-color: $dawn-pink !important;
  }

  .table-1600 {
    width: 1600px;
    transform: translateX(-224px); // original width 1200 - paddings = 1152
  }
}