$primary-color: #AA1E46;
$secondary-color: lighten($primary-color, 47.5%);
$base-font-family: 'Roboto Condensed', sans-serif;

// status colors
$status-red: red;
$status-orange: #FF6400;
$status-yellow: #FFB400;
$status-green: green;

// future works tr colors
$future-red: #CE0000;
$future-orange: #ED8A3F;
$future-yellow: #F7E967;
$future-green: #A9CF54;

// http://sass-lang.com/styleguide/color
$hopbush: #c69;
$bouquet: #b37399;
$venus: #998099;
$patina: #699;
$nebula: #d2e1dd;
$dawn-pink: #f2ece4;
$wafer: #e1d7d2;
$iron: #dadbdf;
$regent-grey: #808c99;
$pale-sky: #6b717f;
$midnight-blue: #036;

// pure.css buttons
$green: rgb(28, 184, 65);
$maroon: rgb(202, 60, 60);
$orange: rgb(223, 117, 20);
$light-blue: rgb(66, 184, 221);

// pure.css inavalid
$invalid: #e9322d;

// rails' messages' background colors
$notice-color: rgb(80, 180, 80);
$alert-color: rgb(220, 180, 110);