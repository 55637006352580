#inn-modal, #bill-edit-modal {
  .modal-container { height: 75vh; }
  .modal-body { flex: 1; }
}

#report-notes-modal {
  .toast { text-align: left; }
}

#report-payment-modal {
  .modal-container { min-height: 60vh; }

  .modal-body { flex: 1; }
  .modal-body:nth-child(3) { flex: 10; }

  #bill-action-choose {
    display: flex;
    justify-content: space-evenly;
  }

  #bill-action-select {
    min-height: 300px;
  }

  input[type="date"]::-webkit-clear-button {
    display: none;
  }
}

#expenses-files-modal {
  .modal-footer div {
    display: inline-block;
  }
}